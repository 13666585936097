import {
  SEARCH_ACTION_ATTRIBUTE_WINNER,
  SEARCH_ACTION_CANCEL_ATTRIBUTION,
  SEARCH_ACTION_CANCEL_SEARCH,
  SEARCH_ACTION_DELETE_TRANSITIONAL_CARE_FORM,
  SEARCH_ACTION_EDIT_SEARCH,
  SEARCH_ACTION_GENERATE_TRANSITIONAL_CARE_FORM,
  SEARCH_ACTION_MOVE_TO_AUTO_SEARCH,
  SEARCH_ACTION_RESTART_SEARCH,
  SEARCH_ACTION_SEND_REQUEST,
  SEARCH_ACTION_SHARE_PATIENT_DATA,
  SEARCH_ACTION_START_AUTOMATIC_SEARCH,
  SEARCH_ACTION_START_MANUAL_SEARCH,
} from "@recare/core/consts";
import { SearchActionsWithHandlers } from "@recare/core/types";
import {
  auction,
  auctionRequest,
  auctionResponse,
  auctionValidation,
  careprovider,
  requestActions,
  searchActions,
  sessionKey,
  transitionalCare,
  transitionalCareFragment,
} from "./fragments";

const startSearchFragment = `
  id
  status
  ${searchActions}
`;

const restartSearchFragment = `
  id
  status
  all_negative
  ${searchActions}
`;

const moveToAutoSearchFragment = `
  id
  status
  ${searchActions}
`;

const sendRequestsFragment = `
  id
  requests {
    ${auctionRequest({
      withRequestAccounts: true,
      withAccountRoles: true,
      withPatient: false,
    })}
    session_key {
      ${sessionKey()}
    }
  }
`;

const cancelSearchFragment = `
  id
  status
  cancel_auction  {
    reason
    description
  }
  ${searchActions}
`;

const validateProviderActionFragment = `
id
status
validation {
  ${auctionValidation()}
}
response {
  ${auctionResponse()}
}
${requestActions}
`;

const wonSearchFragment = `
  id
  status
  winner {
    careprovider {
      ${careprovider()}
    }
    careprovider_name
    unknown_provider
    external_provider
    updated_at
    created_at
    auction_request_id
    auction_request {
      ${validateProviderActionFragment}
    }
    solution
    specialization
  }
  ${searchActions}
`;
const cancelAttributionFragment = `
  id
  status
  winner
  ${searchActions}
`;

const generateTransitionalCareForm = `
${transitionalCare}
profile {
  has_transitional_care
}
`;

const deleteTransitionalCareForm = `
    id
    ${transitionalCareFragment}
    profile {
      has_transitional_care
    }
`;

const sharePatientDataFragment = `
    id
`;

const searchActionFragments: {
  [searchAction in SearchActionsWithHandlers]: string;
} = {
  [SEARCH_ACTION_START_AUTOMATIC_SEARCH]: startSearchFragment,
  [SEARCH_ACTION_START_MANUAL_SEARCH]: startSearchFragment,
  [SEARCH_ACTION_RESTART_SEARCH]: restartSearchFragment,
  [SEARCH_ACTION_MOVE_TO_AUTO_SEARCH]: moveToAutoSearchFragment,
  [SEARCH_ACTION_SEND_REQUEST]: sendRequestsFragment,
  [SEARCH_ACTION_CANCEL_SEARCH]: cancelSearchFragment,
  [SEARCH_ACTION_ATTRIBUTE_WINNER]: wonSearchFragment,
  [SEARCH_ACTION_CANCEL_ATTRIBUTION]: cancelAttributionFragment,
  [SEARCH_ACTION_EDIT_SEARCH]: auction({ withPatient: true }),
  [SEARCH_ACTION_GENERATE_TRANSITIONAL_CARE_FORM]: generateTransitionalCareForm,
  [SEARCH_ACTION_DELETE_TRANSITIONAL_CARE_FORM]: deleteTransitionalCareForm,
  [SEARCH_ACTION_SHARE_PATIENT_DATA]: sharePatientDataFragment,
};

export default searchActionFragments;
