import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import {
  getAriaDescribedByValue,
  getKey,
} from "@recare/core/model/utils/strings";
import {
  FormWatcher,
  StateDispatcher,
  StateDispatcherProps,
  isValid,
} from "@recare/react-forms-state";
import CheckboxInputField, {
  CheckboxProps,
} from "ds/components/CheckboxInputField";
import { TEXT_DARK_PRIMARY } from "ds/materials/colors";
import { margin } from "ds/materials/metrics";
import {
  FONT_FAMILY,
  FONT_SIZE_16,
  LINE_HEIGHT_28,
} from "ds/materials/typography";
import { CSSProperties, Fragment, ReactNode } from "react";
import styled from "styled-components";
import { SubheadingFormLabel } from "../FormComponents/SubheadingFormLabel";
import { Validation } from "../Validation";

export type CheckboxGroupBaseProps = {
  LabelInfo?: ReactNode;
  elementName: string;
  exclude?: Array<number>;
  formControlStyle?: CSSProperties;
  formGroupStyle?: CSSProperties;
  include?: Array<number>;
  label?: string;
  labelStyle?: CSSProperties;
  labelWrapperStyle?: CSSProperties;
  sideMutation?: CheckboxProps["sideMutation"];
  wrapperStyle?: CSSProperties;
};

export type CheckboxGroupProps = CheckboxGroupBaseProps & {
  checkboxLabelStyle?: CheckboxProps["labelStyleOverride"];
  describedBy?: string;
  items: Array<{
    RadioCaption?: string | null;
    id: number;
    label: string;
  }>;
  label: string; // Note: Moved from optional in base type to required here
  required?: boolean;
};

export const CheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

function convertIn(
  inValue: any = [],
  props: { items: { id: number; label: string; value: any }[] },
) {
  return props.items.map((v: { id: number; label: string; value: any }) => ({
    id: v.id.toString(),
    label: v.label,
    value: (!inValue || inValue.constructor !== Array ? [] : inValue).includes(
      v.id,
    ),
  }));
}

function convertOut(outValue: AnyObject) {
  return outValue
    .filter((v: ToType) => v.value)
    .map((v: ToType) => (isNaN(v.id) ? v.id : parseInt(v.id)));
}

function CheckboxGroup({
  checkboxLabelStyle,
  describedBy,
  elementName,
  exclude,
  formControlStyle,
  formGroupStyle,
  include,
  items,
  label,
  LabelInfo,
  labelStyle,
  labelWrapperStyle,
  required,
  sideMutation,
  wrapperStyle,
}: CheckboxGroupProps) {
  const errorTextId = "error-text-id";

  return (
    <FormWatcher watchPath={elementName}>
      {({ watchedValidation }) => {
        const valid = isValid(watchedValidation);
        return (
          <FormControl
            component="fieldset"
            error={!valid}
            style={formControlStyle}
          >
            {/* Wrapping the fieldset in a div for Safari to address flexbox issues, as Safari doesn't treat legend as a flex item correctly.  */}
            <div style={labelWrapperStyle}>
              <SubheadingFormLabel
                sx={{
                  color: TEXT_DARK_PRIMARY,
                  fontFamily: FONT_FAMILY,
                  fontSize: FONT_SIZE_16,
                  lineHeight: LINE_HEIGHT_28,
                  margin: margin(0, 0, 1),
                  wordBreak: "break-all",
                  ...labelStyle,
                }}
                required={required}
              >
                {label}
              </SubheadingFormLabel>
              {LabelInfo}
            </div>
            <CheckboxesWrapper
              style={wrapperStyle}
              data-testid={`checkbox-group-${elementName}`}
            >
              <FormGroup sx={{ height: "inherit", ...formGroupStyle }}>
                {items.map(({ id, label, RadioCaption }) => (
                  <Fragment key={getKey(id, label)}>
                    {RadioCaption && <RadioCaption />}
                    <CheckboxInputField
                      elementName={id.toString()}
                      fieldValidation={valid}
                      hide={
                        exclude?.includes(id) ||
                        (include && !include.includes(id)) ||
                        false
                      }
                      inputProps={{
                        "aria-describedby":
                          getAriaDescribedByValue([!valid && errorTextId]) ||
                          describedBy,
                      }}
                      isGroup
                      label={label}
                      labelStyleOverride={checkboxLabelStyle}
                      sideMutation={sideMutation}
                    />
                  </Fragment>
                ))}
              </FormGroup>
              <Validation
                hasCustomValidation
                id={errorTextId}
                validation={watchedValidation}
                elementName={elementName}
              />
            </CheckboxesWrapper>
          </FormControl>
        );
      }}
    </FormWatcher>
  );
}

const ConnectedCheckboxGroup = StateDispatcher(
  convertIn,
  convertOut,
)<CheckboxGroupProps & StateDispatcherProps>(CheckboxGroup);

export default ConnectedCheckboxGroup;
